html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif !important;
}

header {
  background: linear-gradient(90deg, #5f249f 0%, #a044ff 100%);
  color: #ffffff;
  padding: 10px;
}

.dxc-logo {
  right: 10px;
  top: 5px;
  position: absolute;
  width: 48px;
  cursor: pointer;
}

.app-title {
  display: flex;
  justify-content: left;
  align-items: center;
}

.app-title mat-icon {
  margin: -1px 9px 0px -1px;
  width: 86px;
  height: 36px;
  cursor: pointer;
}

.main-search-box {
  text-align: center;
  color: #ffffff;
  padding-top: 25vh;
}

.body-bg-people-search {
  background: #f4f5f7 !important;
}

.mat-form-field .mat-form-field-label-wrapper .mat-form-field-label,
.mat-option,
.mat-option-text,
.mat-menu-item,
.mat-form-field,
.mat-select,
table.mat-table,
.mat-paginator,
.mat-paginator-page-size .mat-select-trigger,
.mat-tab-group,
.mat-tab-label-content,
.mat-radio-button,
.mat-step,
.mat-horizontal-stepper,
.mat-step-label,
.mat-button-wrapper,
.mat-tree,
.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab,
.mat-button-toggle,
.mat-tab-label,
:host {
  font-family: 'Open Sans', sans-serif !important;
}

h1 {
  font-size: 32pt;
}

h2 {
  font-size: 26pt;
}

h3 {
  font-size: 22pt;
}

h4 {
  font-size: 20pt;
}

.dxc-purple {
  color: #5f249f;
}

.dxc-grey {
  color: #4f4f4f;
}

.dxc-white {
  color: #ffffff;
}

.loader-position {
  position: absolute !important;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0 auto;
}

.dark-overlay {
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
}

.mat-autocomplete-panel {
  max-height: 40vh;
}

.mat-autocomplete-panel a {
  color: black;
}

.mat-step-icon-selected {
  background-color: #5f249f !important;
}

.cdk-global-scrollblock {
  top: 0 !important;
}

.clock-section-favorites-menu.mat-menu-panel {
  border-radius: 4px;
  max-width: unset;
  min-height: unset;
  overflow-x: hidden;
  overflow-y: auto;
}

.clock-section-favorites-menu.mat-menu-panel .mat-menu-content {
  padding: 0px;
}

.clock-section-favorites-menu.mat-menu-panel .mat-menu-list {
  /* max-height: 200px; */
  padding: 10px 20px;
  overflow-y: auto;
}

.fav.mat-menu-panel {
  width: 100vw;
  max-width: 100vw;
  overflow: visible;
  margin-top: 10px;
  margin-left: -78px !important;
  border-radius: 0px;
}

.arrow-wrapper {
  margin-top: 5px;
  position: relative;
  float: right;
  height: 30px;
}

.arrow-wrapper .button-arrow:disabled {
  color: #d9d9d6;
  cursor: default;
}

/* show tooltip info */
.appTooltip {
  cursor: pointer;
  margin-right: 5px;
}

h5.appTooltip {
  display: inline-block;
}

h5.appTooltip mat-icon {
  margin-left: 5px;
  width: 16px;
  height: 16px;
}

.tooltip {
  font-family: 'Open Sans', sans-serif;
  position: absolute;
  width: auto;
  max-width: 200px;
  min-height: 36px;
  padding: 8px;
  background: #63666a;
  display: flex;
  align-items: center;
  color: #ffffff;
  border-radius: 5px;
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  margin: 0px;
  opacity: 0;
  text-align: left;
  transition: opacity 0.5s;
  z-index: 1001;
}

.tooltip:after {
  content: '';
  position: absolute;
  border-style: solid;
}

.tooltip-show {
  opacity: 1;
}

/* align tooltip position in left */
.tooltip-left {
  margin-left: -5px;
}

.tooltip-left:after {
  top: 50%;
  left: 100%;
  margin-top: -10px;
  border-width: 10px;
  border-color: transparent transparent transparent #63666a;
}

/* align tooltip position in right */
.tooltip-right {
  margin-left: 10px;
}

.tooltip-right:after {
  top: 50%;
  right: 100%;
  margin-top: -10px;
  border-width: 10px;
  border-color: transparent #63666a transparent transparent;
}

/* align tooltip position in bottom */
.tooltip-bottom {
  margin-top: 10px;
}

.tooltip-bottom:after {
  bottom: 100%;
  left: 50%;
  margin-left: -10px;
  border-width: 10px;
  border-color: transparent transparent #63666a transparent;
}

/* align tooltip position in top */
.tooltip-top {
  margin-top: -10px;
}

.tooltip-top:after {
  top: 100%;
  left: 50%;
  margin-left: -10px;
  border-width: 10px;
  border-color: #63666a transparent transparent transparent;
}

/* overwrite tooltip box default styles using custom class */
.quick-link-tooltip,
.es-link-tooltip {
  margin-top: 0px;
}

/* display clear icon for input search box */
.clear-input {
  position: absolute;
  right: 0px;
  top: 0px;
  font-size: 32px;
  padding: 0px 10px;
  margin-right: 10px;
  border-right: 1px solid #e6e6e6;
  height: 32px;
  line-height: 34px;
  cursor: pointer;
  color: #969696 !important;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}

.clear-input:hover {
  text-decoration: none;
  color: #63666a !important;
}

.clear-input.show {
  visibility: visible;
  opacity: 1;
}

/* Dialog */
.dialog-header {
  margin: 0;
  padding: 12px;
  border-radius: 10px 10px 0 0;
  background: linear-gradient(90deg, #5f249f 0%, #a044ff 100%);
  font-size: 24px;
  text-align: center;
  color: #ffffff;
}

.dialog-body {
  border-radius: 0 0 10px 10px;
  padding: 24px 55px;
  background: #ffffff;
  max-height: 75vh;
  overflow-y: auto;
}

.dialog-close-button {
  float: right;
  margin-top: 4px;
  color: #ffffff;
  cursor: pointer;
}

/* overwrite default mat-dialog styles */
.profileLinks-dialog-container .mat-dialog-container,
.announcements-dialog-container .mat-dialog-container,
.clock-dialog-container .mat-dialog-container,
.favorites-dialog-container .mat-dialog-container,
.permission-dialog-container .mat-dialog-container,
.edit-permission-dialog-container .mat-dialog-container {
  padding: 0;
  border-radius: 0 0 10px 10px;
  background: none;
}

/* clock-dialog */
.profileLinks-dialog-container .dialog-body,
.announcements-dialog-container .dialog-body,
.clock-dialog-container .dialog-body,
.permission-dialog-container .dialog-body,
.edit-permission-dialog-container .dialog-body {
  padding: 0px 40px 24px;
  overflow-x: hidden;
}

.clock-dialog-container .dialog-body .mat-tab-header,
.permission-dialog-container .dialog-body .mat-tab-header,
.edit-permission-dialog-container .dialog-body .mat-tab-header {
  margin-left: -110px;
  right: -55px;
}

input:focus,
input:focus-within,
input:active,
button:focus,
button:focus-within,
button:active,
input[type='button']:focus,
input[type='button']:focus-within,
input[type='button']:active,
a:focus,
a:focus-within,
a:active {
  outline: 0 none transparent !important;
}

/* user profile dialog */
.profile-dialog-container .mat-dialog-container {
  padding: 0;
  border-radius: 10px;
  overflow: hidden;
}

@media screen and (max-width: 991px) {
  .profile-dialog-container .mat-dialog-container {
    width: 90vw;
  }
}

/* favorites dialog  */
.favorites-dialog-container .mat-dialog-container {
  width: 650px;
  overflow: hidden;
}

.favorites-dialog-container .mat-dialog-container .dialog-body {
  padding: 0px 10px;
}

.favorites-dialog-container .mat-dialog-content {
  position: relative;
  margin: 0px;
  padding: 10px 35px;
  overflow-x: hidden;
  max-height: 50vh;
}

/* announcements dialog  */
.announcements-dialog-container .mat-dialog-container {
  width: 650px;
  overflow: hidden;
}

.announcements-dialog-container .mat-dialog-container .dialog-body {
  padding: 30px 40px 24px;
}

/* profile links dialog  */
.profileLinks-dialog-container .mat-dialog-container {
  width: 650px;
  overflow: hidden;
}

.profileLinks-dialog-container .mat-dialog-container .dialog-body {
  padding: 30px 40px 24px;
}

/* fav empty in tools and resources / fav menu */
.fav-empty {
  text-align: center;
  min-height: 300px;
}

.fav-empty mat-icon {
  width: 15rem;
  height: 15rem;
  margin: 0 auto;
  max-width: fit-content;
}

.fav-empty h2 {
  font-weight: 600;
  font-size: 24px;
  color: #000000;
}

.fav-empty h4 {
  font-weight: 600;
  font-size: 16px;
  margin: 20px auto;
}

/* Open Sans - regular - start  */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/open-sans/open-sans-300.eot'); /* IE9 Compat Modes */
  src: url('./assets/fonts/open-sans/open-sans-300.eot') format('embedded-opentype'); /* IE6-IE8 */
  src: url('./assets/fonts/open-sans/open-sans-300.woff2') format('woff2'); /* Super Modern Browsers */
  src: url('./assets/fonts/open-sans/open-sans-300.woff') format('woff'); /* Modern Browsers */
  src: url('./assets/fonts/open-sans/open-sans-300.ttf') format('truetype'); /* Safari, Android, iOS */
  src: url('./assets/fonts/open-sans/open-sans-300.svg') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/open-sans/open-sans-400.eot'); /* IE9 Compat Modes */
  src: url('./assets/fonts/open-sans/open-sans-400.eot') format('embedded-opentype'); /* IE6-IE8 */
  src: url('./assets/fonts/open-sans/open-sans-400.woff2') format('woff2'); /* Super Modern Browsers */
  src: url('./assets/fonts/open-sans/open-sans-400.woff') format('woff'); /* Modern Browsers */
  src: url('./assets/fonts/open-sans/open-sans-400.ttf') format('truetype'); /* Safari, Android, iOS */
  src: url('./assets/fonts/open-sans/open-sans-400.svg') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/open-sans/open-sans-500.eot'); /* IE9 Compat Modes */
  src: url('./assets/fonts/open-sans/open-sans-500.eot') format('embedded-opentype'); /* IE6-IE8 */
  src: url('./assets/fonts/open-sans/open-sans-500.woff2') format('woff2'); /* Super Modern Browsers */
  src: url('./assets/fonts/open-sans/open-sans-500.woff') format('woff'); /* Modern Browsers */
  src: url('./assets/fonts/open-sans/open-sans-500.ttf') format('truetype'); /* Safari, Android, iOS */
  src: url('./assets/fonts/open-sans/open-sans-500.svg') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/open-sans/open-sans-600.eot'); /* IE9 Compat Modes */
  src: url('./assets/fonts/open-sans/open-sans-600.eot') format('embedded-opentype'); /* IE6-IE8 */
  src: url('./assets/fonts/open-sans/open-sans-600.woff2') format('woff2'); /* Super Modern Browsers */
  src: url('./assets/fonts/open-sans/open-sans-600.woff') format('woff'); /* Modern Browsers */
  src: url('./assets/fonts/open-sans/open-sans-600.ttf') format('truetype'); /* Safari, Android, iOS */
  src: url('./assets/fonts/open-sans/open-sans-600.svg') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/open-sans/open-sans-700.eot'); /* IE9 Compat Modes */
  src: url('./assets/fonts/open-sans/open-sans-700.eot') format('embedded-opentype'); /* IE6-IE8 */
  src: url('./assets/fonts/open-sans/open-sans-700.woff2') format('woff2'); /* Super Modern Browsers */
  src: url('./assets/fonts/open-sans/open-sans-700.woff') format('woff'); /* Modern Browsers */
  src: url('./assets/fonts/open-sans/open-sans-700.ttf') format('truetype'); /* Safari, Android, iOS */
  src: url('./assets/fonts/open-sans/open-sans-700.svg') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url('./assets/fonts/open-sans/open-sans-800.eot'); /* IE9 Compat Modes */
  src: url('./assets/fonts/open-sans/open-sans-800.eot') format('embedded-opentype'); /* IE6-IE8 */
  src: url('./assets/fonts/open-sans/open-sans-800.woff2') format('woff2'); /* Super Modern Browsers */
  src: url('./assets/fonts/open-sans/open-sans-800.woff') format('woff'); /* Modern Browsers */
  src: url('./assets/fonts/open-sans/open-sans-800.ttf') format('truetype'); /* Safari, Android, iOS */
  src: url('./assets/fonts/open-sans/open-sans-800.svg') format('svg'); /* Legacy iOS */
}
/* Open Sans - regular - end */

/* Open Sans - italic - start */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: url('./assets/fonts/open-sans/open-sans-300-italic.eot'); /* IE9 Compat Modes */
  src: url('./assets/fonts/open-sans/open-sans-300-italic.eot') format('embedded-opentype'); /* IE6-IE8 */
  src: url('./assets/fonts/open-sans/open-sans-300-italic.woff2') format('woff2'); /* Super Modern Browsers */
  src: url('./assets/fonts/open-sans/open-sans-300-italic.woff') format('woff'); /* Modern Browsers */
  src: url('./assets/fonts/open-sans/open-sans-300-italic.ttf') format('truetype'); /* Safari, Android, iOS */
  src: url('./assets/fonts/open-sans/open-sans-300-italic.svg') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('./assets/fonts/open-sans/open-sans-400-italic.eot'); /* IE9 Compat Modes */
  src: url('./assets/fonts/open-sans/open-sans-400-italic.eot') format('embedded-opentype'); /* IE6-IE8 */
  src: url('./assets/fonts/open-sans/open-sans-400-italic.woff2') format('woff2'); /* Super Modern Browsers */
  src: url('./assets/fonts/open-sans/open-sans-400-italic.woff') format('woff'); /* Modern Browsers */
  src: url('./assets/fonts/open-sans/open-sans-400-italic.ttf') format('truetype'); /* Safari, Android, iOS */
  src: url('./assets/fonts/open-sans/open-sans-400-italic.svg') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 500;
  src: url('./assets/fonts/open-sans/open-sans-500-italic.eot'); /* IE9 Compat Modes */
  src: url('./assets/fonts/open-sans/open-sans-500-italic.eot') format('embedded-opentype'); /* IE6-IE8 */
  src: url('./assets/fonts/open-sans/open-sans-500-italic.woff2') format('woff2'); /* Super Modern Browsers */
  src: url('./assets/fonts/open-sans/open-sans-500-italic.woff') format('woff'); /* Modern Browsers */
  src: url('./assets/fonts/open-sans/open-sans-500-italic.ttf') format('truetype'); /* Safari, Android, iOS */
  src: url('./assets/fonts/open-sans/open-sans-500-italic.svg') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: url('./assets/fonts/open-sans/open-sans-600-italic.eot'); /* IE9 Compat Modes */
  src: url('./assets/fonts/open-sans/open-sans-600-italic.eot') format('embedded-opentype'); /* IE6-IE8 */
  src: url('./assets/fonts/open-sans/open-sans-600-italic.woff2') format('woff2'); /* Super Modern Browsers */
  src: url('./assets/fonts/open-sans/open-sans-600-italic.woff') format('woff'); /* Modern Browsers */
  src: url('./assets/fonts/open-sans/open-sans-600-italic.ttf') format('truetype'); /* Safari, Android, iOS */
  src: url('./assets/fonts/open-sans/open-sans-600-italic.svg') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: url('./assets/fonts/open-sans/open-sans-700-italic.eot'); /* IE9 Compat Modes */
  src: url('./assets/fonts/open-sans/open-sans-700-italic.eot') format('embedded-opentype'); /* IE6-IE8 */
  src: url('./assets/fonts/open-sans/open-sans-700-italic.woff2') format('woff2'); /* Super Modern Browsers */
  src: url('./assets/fonts/open-sans/open-sans-700-italic.woff') format('woff'); /* Modern Browsers */
  src: url('./assets/fonts/open-sans/open-sans-700-italic.ttf') format('truetype'); /* Safari, Android, iOS */
  src: url('./assets/fonts/open-sans/open-sans-700-italic.svg') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 800;
  src: url('./assets/fonts/open-sans/open-sans-800-italic.eot'); /* IE9 Compat Modes */
  src: url('./assets/fonts/open-sans/open-sans-800-italic.eot') format('embedded-opentype'); /* IE6-IE8 */
  src: url('./assets/fonts/open-sans/open-sans-800-italic.woff2') format('woff2'); /* Super Modern Browsers */
  src: url('./assets/fonts/open-sans/open-sans-800-italic.woff') format('woff'); /* Modern Browsers */
  src: url('./assets/fonts/open-sans/open-sans-800-italic.ttf') format('truetype'); /* Safari, Android, iOS */
  src: url('./assets/fonts/open-sans/open-sans-800-italic.svg') format('svg'); /* Legacy iOS */
}
/* Open Sans - italic - end */

.permission-dialog-container .mat-form-field-infix,
.edit-permission-dialog-container .mat-form-field-infix {
  margin-top: -22px !important;
  font-size: 14px;
  width: 260px;
}

.permission-dialog-container .mat-checkbox-layout .mat-checkbox-label,
.edit-permission-dialog-container .mat-checkbox-layout .mat-checkbox-label,
.permission-dialog-container .department-region-selector {
  font-weight: 400;
  font-size: 14px;
}

.permission-dialog-container,
.edit-permission-dialog-container {
  width: 650px !important;
  height: auto !important;
  margin-top: 3% !important;
}

.profileLinksPaginator .mat-paginator-container,
.addPermissionPaginator .mat-paginator-container {
  background: #d9d9d6 !important;
  color: #000000 !important;
  font-size: 16px !important;
  margin-top: -2px !important;
  padding-right: 24px !important;
}

.profileLinksPaginator .mat-paginator-range-actions .mat-icon-button,
.addPermissionPaginator .mat-paginator-range-actions .mat-icon-button {
  border-radius: 5px;
  margin: 5px;
  width: 32px;
  height: 32px;
  line-height: 22px;
  background: #ffffff;
}

.admin-container .mat-tab-label-active {
  color: #5f249f !important;
  font-weight: 600;
  border-bottom: 1.5px solid #5f249f;
  opacity: unset;
  font-size: 16px;
}

.admin-container .mat-tab-body:nth-child(2) .mat-tab-label-active {
  background: #dfe1e6;
  border-bottom: none !important;
  border-radius: 33px;
  padding: 12px, 8px, 12px, 8px;
}

.admin-container .admin-content-tab-nav .mat-tab-header {
  border-bottom: none;
}

.admin-container .mat-tab-group.mat-primary .mat-ink-bar {
  background: none !important;
}

.admin-container .mat-tab-body-content {
  min-height: 300px;
  padding: 20px 20px;
}

.addpermission-overlay .department-region-selector .mat-select-value {
  margin: 23px 0px 9px 10px !important;
}

.addpermission-overlay .department-region-selector .mat-select-arrow-wrapper {
  margin-top: 10px;
}

.addpermission-overlay .department-region-selector mat-select.mat-focused .mat-select-trigger .mat-select-arrow {
  color: #63961c;
}

.addpermission-overlay .mat-select-placeholder {
  font-weight: 400;
  font-family: sans-serif;
  color: #06142d;
}

.modal-sm {
  max-width: 520px !important;
}

.edit-permission.modal-footer .btn-primary {
  background-color: #5f249f !important;
}

.admin-permissions-container .add-permission-close {
  float: right;
  position: absolute;
  right: 6%;
}

span.add-permission-close mat-icon {
  height: 14px;
  width: 15px;
  cursor: pointer;
}

body.add-permission-form-body {
  background: #eceef1 !important;
}
